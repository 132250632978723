.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiTableCell-root {
  height: 14px !important;
  padding: 8px !important;
  padding-left: 24px !important;
  font-size: 14px !important;
}

.MuiTableCell-footer.MuiTableCell-root {
  height: 70px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ellipsistext {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SnackbarItem-variantSuccess {
  background-color: #7dc622 !important;
}
.SnackbarItem-variantInfo {
  background-color: #00a49e !important;
}
.SnackbarItem-variantError {
  background-color: #df0029 !important;
}

.userImageWrapper {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: #fff;
  cursor: 'pointer';
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.light-theme {
  background-color: #fff !important;
  color: #000 !important;
}

.dark-theme {
  background-color: #000 !important;
  color: #fff !important;
}